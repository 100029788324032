import React from "react";
import { Banner } from "../../../assets";
import { useNavigate } from "react-router-dom";
import "./styles.css";

const Navbar = () => {
  const [isScrolled, setIsScrolled] = React.useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
  const navigate = useNavigate();
  const loc = window.location.pathname;

  React.useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    handleScroll();

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  React.useEffect(() => {
    document.body.style.overflow = isMobileMenuOpen ? "hidden" : "auto";
  }, [isMobileMenuOpen]);

  function handleClick() {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  }

  function navigateToPage(e) {
    e.preventDefault();
    const path = e.target.getAttribute("href");
    if (!path) return navigate("/");
    navigate(path);
    window.scrollTo(0, 0);
    return 0;
  }

  return (
    <div className={`container navbar ${isScrolled ? "scrolled" : ""}`}>
      <div className="wrapper">
        <div className="left">
          <a onClick={navigateToPage} href="/" className="logo">
            <img src={Banner} alt="" />
          </a>
          <a
            onClick={navigateToPage}
            className={loc === "/about" ? "active" : ""}
            href="/about"
          >
            About & Media
          </a>
          <a
            onClick={navigateToPage}
            className={loc === "/reviews" ? "active" : ""}
            href="/reviews"
          >
            Reviews
          </a>
          <a
            onClick={navigateToPage}
            className={loc === "/events" ? "active" : ""}
            href="/events"
          >
            Book an Event
          </a>
          <a
            onClick={navigateToPage}
            className={loc === "/contact" ? "active" : ""}
            href="/contact"
          >
            Contact
          </a>
        </div>
        <div className="right">
          <a onClick={navigateToPage} href="/order" className="button">
            Order Now
            <svg
              width={20}
              height={20}
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.5 7.5H11"
                stroke="currentColor"
                strokeWidth={2}
                className="line"
              ></path>

              <path
                d="M8.14645 3.14645C8.34171 2.95118 8.65829 2.95118 8.85355 3.14645L12.8536 7.14645C13.0488 7.34171 13.0488 7.65829 12.8536 7.85355L8.85355 11.8536C8.65829 12.0488 8.34171 12.0488 8.14645 11.8536C7.95118 11.6583 7.95118 11.3417 8.14645 11.1464L11.2929 8"
                fill="currentColor"
                className="arrow-head"
              ></path>
            </svg>
          </a>
        </div>
        <div onClick={handleClick} className="menu">
          {!isMobileMenuOpen ? (
            <svg
              width={20}
              height={20}
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 3C1.22386 3 1 3.22386 1 3.5C1 3.77614 1.22386 4 1.5 4H13.5C13.7761 4 14 3.77614 14 3.5C14 3.22386 13.7761 3 13.5 3H1.5ZM1 7.5C1 7.22386 1.22386 7 1.5 7H13.5C13.7761 7 14 7.22386 14 7.5C14 7.77614 13.7761 8 13.5 8H1.5C1.22386 8 1 7.77614 1 7.5ZM1 11.5C1 11.2239 1.22386 11 1.5 11H13.5C13.7761 11 14 11.2239 14 11.5C14 11.7761 13.7761 12 13.5 12H1.5C1.22386 12 1 11.7761 1 11.5Z"
                fill="currentColor"
                fillRule="evenodd"
                clipRule="evenodd"
              ></path>
            </svg>
          ) : (
            <svg
              width={20}
              height={20}
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.8536 2.85355C13.0488 2.65829 13.0488 2.34171 12.8536 2.14645C12.6583 1.95118 12.3417 1.95118 12.1464 2.14645L7.5 6.79289L2.85355 2.14645C2.65829 1.95118 2.34171 1.95118 2.14645 2.14645C1.95118 2.34171 1.95118 2.65829 2.14645 2.85355L6.79289 7.5L2.14645 12.1464C1.95118 12.3417 1.95118 12.6583 2.14645 12.8536C2.34171 13.0488 2.65829 13.0488 2.85355 12.8536L7.5 8.20711L12.1464 12.8536C12.3417 13.0488 12.6583 13.0488 12.8536 12.8536C13.0488 12.6583 13.0488 12.3417 12.8536 12.1464L8.20711 7.5L12.8536 2.85355Z"
                fill="currentColor"
                fillRule="evenodd"
                clipRule="evenodd"
              ></path>
            </svg>
          )}
        </div>
        <MobileComponent
          isOpen={isMobileMenuOpen}
          loc={loc}
          handleClick={navigateToPage}
        />
      </div>
    </div>
  );
};

const MobileComponent = ({ isOpen, loc, handleClick }) => {
  return (
    <div className={`mobile ${isOpen ? "open" : ""}`}>
      <div className="wrapper">
        <a
          onClick={handleClick}
          className={loc === "/about" ? "active" : ""}
          href="/about"
        >
          <span>About & Media</span>
        </a>
        <a
          onClick={handleClick}
          className={loc === "/reviews" ? "active" : ""}
          href="/reviews"
        >
          <span>Reviews</span>
        </a>
        <a
          onClick={handleClick}
          className={loc === "/events" ? "active" : ""}
          href="/events"
        >
          <span>Book an Event</span>
        </a>
        <a
          onClick={handleClick}
          className={loc === "/contact" ? "active" : ""}
          href="/contact"
        >
          <span>Contact</span>
        </a>
        <a onClick={handleClick} href="/order" className="button">
          Order Now
          <svg
            width={20}
            height={20}
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.5 7.5H11"
              stroke="currentColor"
              strokeWidth={2}
              className="line"
            ></path>

            <path
              d="M8.14645 3.14645C8.34171 2.95118 8.65829 2.95118 8.85355 3.14645L12.8536 7.14645C13.0488 7.34171 13.0488 7.65829 12.8536 7.85355L8.85355 11.8536C8.65829 12.0488 8.34171 12.0488 8.14645 11.8536C7.95118 11.6583 7.95118 11.3417 8.14645 11.1464L11.2929 8"
              fill="currentColor"
              className="arrow-head"
            ></path>
          </svg>
        </a>
      </div>
    </div>
  );
};

export default Navbar;
