import React from "react";
import "./styles.css";

const Divide = ({ spacing = false }) => {
  return (
    <div className={`container divide ${spacing ? "spaced" : ""}`}>
      <div className="wrapper"></div>
    </div>
  );
};

export default Divide;
