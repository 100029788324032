import React from "react";
import "./component.css";

const NavUp = () => {
  const [hidden, setHidden] = React.useState(window.scrollY > window.innerHeight * 0.5);
  const [direction, setDirection] = React.useState(1);

  React.useEffect(() => {
    const updateVisibility = () => {
      setHidden(window.scrollY <= window.innerHeight * 0.5);
    };
    window.addEventListener("scroll", updateVisibility, { passive: true });
    updateVisibility(); 
    return () => window.removeEventListener("scroll", updateVisibility);
  }, []);

  const handleClick = () => {
    window.scrollTo({
      top: direction === 1 ? 0 : document.body.scrollHeight,
      behavior: "smooth",
    });
  };

  const handleChangeDirection = (e) => {
    e.preventDefault();
    setDirection(-direction);
  };

  return (
    <div
      onClick={handleClick}
      onContextMenu={handleChangeDirection}
      className={`component navup ${hidden ? "hidden" : ""} ${direction === 1 ? "" : "flip"}`}
    >
      <svg width={20} height={20} viewBox="0 0 15 15" fill="none" className="icon" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.13523 8.84197C3.3241 9.04343 3.64052 9.05363 3.84197 8.86477L7.5 5.43536L11.158 8.86477C11.3595 9.05363 11.6759 9.04343 11.8648 8.84197C12.0536 8.64051 12.0434 8.32409 11.842 8.13523L7.84197 4.38523C7.64964 4.20492 7.35036 4.20492 7.15803 4.38523L3.15803 8.13523C2.95657 8.32409 2.94637 8.64051 3.13523 8.84197Z"
          fill="currentColor"
          fillRule="evenodd"
          clipRule="evenodd"
        ></path>
      </svg>
    </div>
  );
};

export default NavUp;
