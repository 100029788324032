import React from "react";
import { Banner } from "../../../assets";
import "./styles.css";

const Footer = () => {
  return (
    <footer className="container footer">
      <div className="top">
        <div className="wrapper">
          <div className="logo">
            <a href="/" className="logo_link">
              <img height={79.06} width={100} src={Banner} alt="" />
            </a>
          </div>
          <div className="cols">
            <div className="col">
                <header>Discover & Connect</header>
                <ul>
                    <li>
                        <a href="/">Home</a>
                    </li>
                    <li>
                        <a href="/about">About & Media</a>
                    </li>
                    <li>
                        <a href="/contact">Contact</a>
                    </li>
                </ul>
            </div>
            <div className="col">
                <header>Engage</header>
                <ul>
                    <li>
                        <a href="/events">Book an Event</a>
                    </li>
                    <li>
                        <a href="/events?redir=cal">Calendar</a>
                    </li>
                    <li>
                        <a href="/topics">Presentation Topics</a>
                    </li>
                </ul>
            </div>
            <div className="col">
                <header>Review & Purchase</header>
                <ul>
                    <li>
                        <a href="/order">Order</a>
                    </li>
                    <li>
                        <a href="/reviews">Reviews</a>
                    </li>
                    <li>
                      <a href="/about?nav=recommendations">Recommendations</a>
                    </li>
                </ul>
            </div>
          </div>
          <div className="socials">
            <header>Socials</header>
            <ul>
              <li>
                <a className="youtube" target="_blank" href="https://www.youtube.com/@JulieKabat1964">
                  <svg
                    width={20}
                    height={20}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                    fill="currentColor"
                  >
                    <path d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z" />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="bottom">
        <div className="wrapper">
          <div className="left">
            <a href="">Privacy Policy</a>
            <a href="">Cookie Policy</a>
          </div>
          <div className="right">
            <p>Copyright {new Date().getFullYear()} &copy; Julie Kabat</p>
            <p>All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
