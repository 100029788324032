import React from "react";
import { Event } from "../../../assets";
import { useLightbox, useNotifications } from "../../../hooks";
import "./styles.css";

const EventAbt = () => {
  const lightbox = useLightbox();
  const noti = useNotifications();

  return (
    <div className="container eventabt">
      <div className="wrapper">
        <div className="left">
          <h2>Big or Small, Julie has an Event for You</h2>
          <div className="img-wrapper">
            <img
              onClick={() =>
                lightbox.create(
                  Event,
                  "Photo of Tamani Wooley, Julie Kabat, and Andreesa Coleman (Luke's former Freedom School student) at Capital Repertory Theater Company, Albany NY",
                  "Pre Show Talk",
                  "Tamani Wooley, Andreesa Coleman, and Julie Kabat at Capital Repertory Theater Company, Albany NY"
                )
              }
              src={Event}
              alt="Photo of Tamani Wooley, Julie Kabat, and Andreesa Coleman (Luke's former Freedom School student) at Capital Repertory Theater Company, Albany NY"
            />
            <div className="text">
              <span className="title">
                Tamani Wooley, Julie Kabat, and Andreesa Coleman (Luke's former
                Freedom School student) at Capital Repertory Theater Company,
                Albany NY
              </span>
            </div>
          </div>
          <p>
            Julie's presentations foster dynamic conversations around social
            justice, history, and activism. They can include multimedia elements
            like videos and imagery. Whether your event draws a diverse audience
            or focuses on specific themes, Julie's talks are tailored to
            inspire, educate, and energize participants of all ages.
          </p>
          <p></p>
          <div className="wrapper">
            <div className="item">
              <h4>Interactive</h4>
              <p>
                Julie gets the audience singing freedom songs. Her presentations
                may be in person or virtual.
              </p>
            </div>
            <div className="item">
              <h4>Close to Home</h4>
              <p>
                Within a 50-mile radius of her home, Julie may be joined by
                Andreesa Coleman, Luke’s Freedom School student.
              </p>
            </div>
          </div>
          <div className="actions">
            <a href="/contact?nav=form">
              <svg
                width={20}
                height={20}
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 2C0.447715 2 0 2.44772 0 3V12C0 12.5523 0.447715 13 1 13H14C14.5523 13 15 12.5523 15 12V3C15 2.44772 14.5523 2 14 2H1ZM1 3L14 3V3.92494C13.9174 3.92486 13.8338 3.94751 13.7589 3.99505L7.5 7.96703L1.24112 3.99505C1.16621 3.94751 1.0826 3.92486 1 3.92494V3ZM1 4.90797V12H14V4.90797L7.74112 8.87995C7.59394 8.97335 7.40606 8.97335 7.25888 8.87995L1 4.90797Z"
                  fill="currentColor"
                  fillRule="evenodd"
                  clipRule="evenodd"
                ></path>
              </svg>
              Reach Out
            </a>
            <a href="/topics" className="arrow">
              Possible Topics
              <svg
                width={20}
                height={20}
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.5 7.5H11"
                  stroke="currentColor"
                  strokeWidth={2}
                  className="line"
                ></path>

                <path
                  d="M8.14645 3.14645C8.34171 2.95118 8.65829 2.95118 8.85355 3.14645L12.8536 7.14645C13.0488 7.34171 13.0488 7.65829 12.8536 7.85355L8.85355 11.8536C8.65829 12.0488 8.34171 12.0488 8.14645 11.8536C7.95118 11.6583 7.95118 11.3417 8.14645 11.1464L11.2929 8"
                  fill="currentColor"
                  className="arrow-head"
                ></path>
              </svg>
            </a>
          </div>
        </div>
        <div className="right">
          <img
            onClick={() =>
              lightbox.create(
                Event,
                "Photo of Tamani Wooley, Julie Kabat, and Andreesa Coleman (Luke's former Freedom School student) at Capital Repertory Theater Company, Albany NY",
                "Pre Show Talk",
                "Tamani Wooley, Andreesa Coleman, and Julie Kabat at Capital Repertory Theater Company, Albany NY"
              )
            }
            src={Event}
            alt="Photo of Tamani Wooley, Julie Kabat, and Andreesa Coleman (Luke's former Freedom School student) at Capital Repertory Theater Company, Albany NY"
          />
          <div className="text">
            <span className="title">
              Tamani Wooley, Julie Kabat, and Andreesa Coleman (Luke's former
              Freedom School student) at Capital Repertory Theater Company,
              Albany NY
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventAbt;
