import React from "react";
import { Helmet } from "react-helmet";
import {  Credit, Divide, Footer, Navbar, OrderAudio, OrderFirst, OrderHero } from "../../../containers";
import "./view.css";

const Order = () => {
  return (
    <div className="view order">
      <Helmet>
        <title>Order</title>
      </Helmet>
      <div className="container">
        <Navbar />
        <div className="content">
          <OrderFirst />
          <OrderHero />
          <Divide spacing />
          <OrderAudio />
          <Divide spacing />
          <Footer />
          <Credit />
        </div>
      </div>
    </div>
  );
};

export default Order;
