import React from "react";
import "./styles.css";

const Dropdown = ({
  options = ["Option 1", "Option 2"],
  selected = "Option 1",
  onChange = () => {},
}) => {
  const [isActive, setIsActive] = React.useState(false);



  return (
    <div className={`component dropdown ${isActive ? "active" : ""}`}>
      <div onClick={() => setIsActive(!isActive)} className="select">
        <span className="selected">{selected}</span>
        <div className="caret"></div>
      </div>
      <ul className="menu">
        {options.map((option, index) => {
          const isSelected = option === selected;

          return (
            <li 
                key={index} 
                className={`item ${isSelected ? "selected" : ""}`}
                onClick={() => {
                    onChange(option);
                    setIsActive(false);
                }}
            >
              {option}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Dropdown;
