import React from "react";
import { Cover } from "../../../assets";
import { useNavigate } from "react-router-dom";
import "./styles.css";

const HomeHero = () => {
  const n = useNavigate();

  function goTo(a, b) {
    a.preventDefault();
    n(b);
    window.scrollTo(0, 0);
  }

  return (
    <div className="container homehero">
      <div className="wrapper">
        <div className="left">
          <h1>Troubling Times – A Fractured Country.</h1>
          <h2>We've been here before</h2>
          <p>
            In the summer of 1964, the FBI found the smoldering remains of the
            station wagon that James Chaney, Michael Schwerner, and Andrew
            Goodman had been driving before their disappearance. Shortly after
            this awful discovery, Julie Kabat’s beloved brother Luke arrived as
            a volunteer for the Mississippi Summer Project. He was one of more
            than seven hundred volunteers from the North who assisted Black
            civil rights activists and clergy to challenge white supremacy in
            the nation’s most segregated state.
          </p>
          <div className="buttons">
            <a onClick={(e) => goTo(e, "/order")} href="/order">
              <svg
                fill="currentColor"
                width={20}
                height={20}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
              >
                <path d="M0 24C0 10.7 10.7 0 24 0L69.5 0c22 0 41.5 12.8 50.6 32l411 0c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3l-288.5 0 5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5L488 336c13.3 0 24 10.7 24 24s-10.7 24-24 24l-288.3 0c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5L24 48C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
              </svg>
              <span className="text">Order Now</span>
            </a>
            <a
              onClick={(e) => goTo(e, "/events")}
              href="/events"
              className="arrow"
            >
              Events
              <svg
                width={20}
                height={20}
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.5 7.5H11"
                  stroke="currentColor"
                  strokeWidth={2}
                  className="line"
                ></path>

                <path
                  d="M8.14645 3.14645C8.34171 2.95118 8.65829 2.95118 8.85355 3.14645L12.8536 7.14645C13.0488 7.34171 13.0488 7.65829 12.8536 7.85355L8.85355 11.8536C8.65829 12.0488 8.34171 12.0488 8.14645 11.8536C7.95118 11.6583 7.95118 11.3417 8.14645 11.1464L11.2929 8"
                  fill="currentColor"
                  className="arrow-head"
                ></path>
              </svg>
            </a>
          </div>
        </div>
        <div className="right">
          <img
            fetchpriority="high"
            decoding="async"
            src={Cover}
            width={535}
            height={801}
            alt="The book cover of Love Letter From Pig: My Borther's Story of Freedom Summer, by Julie Kabat"
          />
        </div>
      </div>
    </div>
  );
};

export default HomeHero;
