import React from "react";
import { Cover2, Julie2 } from "../../../assets";
import { useLightbox } from "../../../hooks";
import "./styles.css";

const OrderAudio = () => {
  const lightbox = useLightbox();

  return (
    <div className="container orderaudio">
      <div className="wrapper">
        <div className="left">
          <img
            src={Julie2}
            alt="Photo of the author, Julie Kabat, in the recording studio"
            onClick={() =>
              lightbox.create(
                Julie2,
                "Photo of the author, Julie Kabat, in the recording studio",
                null,
                "Photo by Jason Slater Brown, Audio Engineer - BMA Audio"
              )
            }
            width={535}
            height={801}
          />
        </div>
        <div className="right">
          <h1>Order the Audiobook</h1>
          <img
            src={Julie2}
            alt="Photo of the author, Julie Kabat, in the recording studio"
            onClick={() =>
              lightbox.create(
                Julie2,
                "Photo of the author, Julie Kabat, in the recording studio",
                null,
                "Photo by Jason Slater Brown, Audio Engineer - BMA Audio"
              )
            }
            width={535}
            height={801}
          />
          <ul>
            <li>
              <a
                target="_blank"
                href="https://www.audible.com/pd/Love-Letter-from-Pig-Audiobook/B0D3271VMH"
              >
                Audible
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://libro.fm/audiobooks/9798875127397-love-letter-from-pig"
              >
                Libro.fm
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.audiobooksnow.com/audiobook/love-letter-from-pig/10276694/"
              >
                Audiobooks Now
              </a>
            </li>
            <li>
              <a
                href="https://www.chirpbooks.com/audiobooks/love-letter-from-pig-by-julie-p-kabat?srsltid=AfmBOorae83zs2U8NhKnxeZRaobmIdqUvukOuuPuWvush9DeuD_lZAK5"
                target="_blank"
              >
                Chirp
              </a>
            </li>

            <li>
              <a
                target="_blank"
                href="https://www.amazon.com/Love-Letter-Pig-Brothers-Freedom/dp/B0CW56YJ8F/ref=tmm_abk_swatch_0?_encoding=UTF8&qid=&sr="
              >
                Amazon
              </a>
            </li>
            <li>Other sites are also available.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default OrderAudio;
