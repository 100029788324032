import React from 'react';
import { BG2 } from '../../../assets'
import './styles.css'

const ContactHero = () => {
  return (
    <div style={{
        background: `linear-gradient(#000000a1, #000000a1), url('${BG2}')`,
      }} className='container contacthero'>
      <div className="wrapper">
        <h1>Contact Us</h1>
      </div>
    </div>
  )
}

export default ContactHero
