import React from "react";
import { useLightbox } from "../../../hooks";
import { Read } from "../../../assets";
import "./styles.css";

const OrderHero = () => {
  const lightbox = useLightbox();
  const [isLoading, setIsLoading] = React.useState(true);

  function handleClick(e) {
    e.preventDefault();

    if (e.target.id === "book") {
      document
        .querySelector(".container.orderbook")
        .scrollIntoView({ behavior: "smooth" });
    } else {
      document
        .querySelector(".container.orderaudio")
        .scrollIntoView({ behavior: "smooth" });
    }
  }

  return (
    <div className={`container orderhero ${isLoading ? "loading" : ""}`}>
      <div className="wrapper">
        <div className="left">
          <div className="box">
            <img
              onClick={() => lightbox.create(Read, null, null, null)}
              src={Read}
              alt=""
              style={{
                boxShadow: isLoading
                  ? "none !important"
                  : "0 50px 35px -20px rgba(0, 0, 0, 0.23)",
              }}
              className="img"
              fetchPriority="high"
              onLoad={() => setIsLoading(false)}
            />
            <div className="skel"></div>
          </div>
        </div>
        <div className="right">
          <h1>Available Now!</h1>
          <div className="box">
            <img
              onClick={() => lightbox.create(Read, null, null, null)}
              src={Read}
              alt=""
              className="img"
              fetchPriority="high"
              onLoad={() => setIsLoading(false)}
            />
          </div>
          {/* <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint velit
            iste dolor dignissimos officia qui alias est ipsam, ratione officiis
            nisi eveniet! Consectetur repudiandae veniam aliquid? Laboriosam
            labore in rem.
          </p> */}
          <div className="blurbs">
            <div className="book">
              <h3>Hardcover</h3>
              <p>
                Published by the University Press of Mississippi in August 2023,{" "}
                <i>Love Letter from Pig</i> is 272 pages long, and has 36 black
                and white illustrations.
              </p>
              <a onClick={handleClick} id="book" className="arrow">
                Purchase
                <svg
                  width={20}
                  height={20}
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.5 7.5H11"
                    stroke="currentColor"
                    strokeWidth={2}
                    className="line"
                  ></path>

                  <path
                    d="M8.14645 3.14645C8.34171 2.95118 8.65829 2.95118 8.85355 3.14645L12.8536 7.14645C13.0488 7.34171 13.0488 7.65829 12.8536 7.85355L8.85355 11.8536C8.65829 12.0488 8.34171 12.0488 8.14645 11.8536C7.95118 11.6583 7.95118 11.3417 8.14645 11.1464L11.2929 8"
                    fill="currentColor"
                    className="arrow-head"
                  ></path>
                </svg>
              </a>
            </div>
            <div className="audio">
              <h3>Audiobook</h3>
              <p>
                Author Julie Kabat narrates the audiobook, published in May
                2024. It runs 7 hours and 54 minutes.
              </p>
              <a
                onClick={handleClick}
                href="/contact?nav=form"
                className="arrow"
              >
                Buy now
                <svg
                  width={20}
                  height={20}
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.5 7.5H11"
                    stroke="currentColor"
                    strokeWidth={2}
                    className="line"
                  ></path>

                  <path
                    d="M8.14645 3.14645C8.34171 2.95118 8.65829 2.95118 8.85355 3.14645L12.8536 7.14645C13.0488 7.34171 13.0488 7.65829 12.8536 7.85355L8.85355 11.8536C8.65829 12.0488 8.34171 12.0488 8.14645 11.8536C7.95118 11.6583 7.95118 11.3417 8.14645 11.1464L11.2929 8"
                    fill="currentColor"
                    className="arrow-head"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderHero;
