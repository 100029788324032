import React from "react";
import { Recommend } from "../../../assets";
import "./styles.css";

const AboutRec = () => {
  return (
    <div className="container abtrec">
      <div className="wrapper">
        <header>
          <h2>What I Recommend</h2>
        </header>
        <div className="recs">
          <div className="rec">
            <img src={Recommend} alt="" />
            <div className="text">
              <h2>5 Books that Build Compassion Around Issues of Race</h2>
              <p>
                Ben Fox, founder of Shepherd, writes that he “is on a mission to
                help everyone find the book they didn't know they were looking
                for.” He asks authors to share five of their favorite books
                around a topic, theme, or mood and why they love each book.
                Check it out!
              </p>
              <a href="https://shepherd.com/best-books/building-compassion-around-issues-of-race">
                Shepherd.com
                <span className="material-symbols-outlined icon">
                  arrow_forward
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutRec;
