import React from "react";
import "./styles.css";

const AboutQuote = ({ quote = "", author = "", className = "" }) => {
  const createMarkup = (content) => {
    return { __html: content };
  };

  return (
    <div className={`container aboutquote ${className}`}>
      <div className="wrapper">
        <h2 dangerouslySetInnerHTML={createMarkup(quote)} />
        <h4 dangerouslySetInnerHTML={createMarkup(author)} />
      </div>
    </div>
  );
};

export default AboutQuote;
