import React from "react";
import { Helmet } from "react-helmet";
import { ContactForm, ContactHero, Credit, Footer, Navbar } from "../../../containers";
import "./view.css";

const Contact = () => {

  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const nav = searchParams.get("nav");

    if (nav === "form") {
      document.querySelector(".container.contactform").scrollIntoView({ behavior: "smooth" });
    }

    removeSearchParams();
  }, []);

  function removeSearchParams() {
    const url = new URL(window.location.href);
    url.searchParams.delete("nav");
    window.history.replaceState({}, "", url);
  }

  return (
    <div className="view contact">
      <Helmet>
        <title>Contact</title>
      </Helmet>
      <div className="container">
        <Navbar />
        <div className="content">
          <ContactHero />
          <ContactForm />
          <Footer />
          <Credit />
        </div>
      </div>
    </div>
  );
};

export default Contact;
