import React from "react";
import "./styles.css";

const AboutMedia = () => {
  return (
    <div className="container aboutmedia">
      <div className="wrapper">

     
      <header>
        <h2>Media</h2>
      </header>
      <div className="grid">
        <div className="media loading">
          <iframe
            height="315"
            src="https://www.youtube.com/embed/JXdhW7NIBUU?si=Cin72MDh12p8Fcy-"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            onLoad={(e) => {
              e.target.parentElement.classList.remove("loading");
            }}
          ></iframe>
          <div className="text">
            <h2>Mississippi Civil Rights Museum – Excerpt of Q&A*</h2>
            <p>
              Lenray Gandy, who was 9 years old during Freedom Summer, surprises
              Julie Kabat and Gail Falk (Luke's fellow Freedom School teacher)
              at the end of their 'History is Lunch' presentation at the
              Mississippi Civil Rights Museum, Jackson, MS
            </p>
          </div>
        </div>
        <div className="media loading">
          <iframe
            height="315"
            src="https://www.youtube.com/embed/ULiktVYlBBk?si=-0bqffDMbBP23-ij"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            onLoad={(e) => {
              e.target.parentElement.classList.remove("loading");
            }}
          ></iframe>
          <div className="text">
            <h2>Freedom Summer and its Aftermath— Making History</h2>
            <p>
              In 1964, the murders of three young civil rights workers––Mickey
              Schwerner, James Chaney and Andrew Goodman––shocked the nation.
              This conversation reflects on the community’s silent march to
              James Chaney’s funeral.
              <br />
              <br />A conversation with author Julie Kabat, Gail Falk (Freedom
              Summer volunteer) and Andreesa Coleman (Freedom School student).
              Introduction by Steve Yates, Associate Director/Marketing
              Director, University Press of Mississippi.
            </p>
          </div>
        </div>
        <div className="media loading">
          <iframe
            height="315"
            src="https://www.youtube.com/embed/C4qC0OxMzfo?si=nTyedmf2usUpbVSQ"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            onLoad={(e) => {
              e.target.parentElement.classList.remove("loading");
            }}
          ></iframe>
          <div className="text">
            <h2>
              Lessons in Social Justice and Medicine from Freedom Summer 1964
            </h2>
            <p>
              Freedom Summer was a tipping point. What major changes in
              healthcare occurred as a result? Julie Kabat presents an Ethics
              Grand Rounds at the Alden March Bioethics Center, followed by
              dialogue with medical students and faculty, Albany Medical
              College, Albany, NY.{" "}
            </p>
          </div>
        </div>
        <div className="media loading">
          <iframe
            height="315"
            src="https://www.youtube.com/embed/_S1FDU5_sBI?si=0ImPMZAprLSBpQtw"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            onLoad={(e) => {
              e.target.parentElement.classList.remove("loading");
            }}
          ></iframe>
          <div className="text">
            <h2>
              Relationships: Breaking Down Barriers During Freedom Summer 1964{" "}
            </h2>
            <p>
              What was the experience of segregation, and how did Freedom Summer
              begin to break down racial barriers?
              <br />
              <br />A conversation with author Julie Kabat, Gail Falk (Freedom
              Summer volunteer) and Andreesa Coleman (Freedom School student).
              Introduction by Steve Yates, Associate Director/Marketing
              Director, University Press of Mississippi.
            </p>
          </div>
        </div>
        <div className="media loading">
          <iframe
            height="315"
            src="https://www.siena.edu/offices/academics/academic-events-and-programs/mlk-lecture-series/journey-to-freedom-2/videos/"
            title="Siena College: The Journey to Freedom"
            frameBorder="0"
            referrerPolicy="strict-origin-when-cross-origin"
            onLoad={(e) => {
              e.target.parentElement.classList.remove("loading");
            }}
          ></iframe>
          <div className="text">
            <a
              href="https://www.siena.edu/offices/academics/academic-events-and-programs/mlk-lecture-series/journey-to-freedom-2/videos/"
              className="title"
            >
              “The Journey to Freedom,” a video series co-produced by Paul
              Murray, sociologist and Siena College professor emeritus{" "}
            </a>
            <p>
              The Journey to Freedom project has recorded the stories of women
              and men from the Capital Region of New York who participated in
              the Civil Rights Movement of the 1960s. Many traveled to the
              segregated Deep South. Others were active in their home
              communities. All worked to advance social justice. 
              <br />
              <br />
              These videos show that the success of the Movement depended not
              only on the heroism of its celebrated leaders, but also on the
              dedication and sacrifices of many thousand everyday people––the
              "foot soldiers" of the Movement. 
              <br />
              <br />
              Paul Murray, Co-Producer; Donald Hyman, Co-Producer; Zebulon
              Schmidt, Videographer; Kirk Daniels, Videographer
            </p>
          </div>
        </div>
        <div className="media loading">
          <iframe
            height="315"
            src="https://www.youtube.com/embed/cBZjAkIdjSo?si=hie-9E6fYADMqfd7&amp;start=10"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            onLoad={(e) => {
              e.target.parentElement.classList.remove("loading");
            }}
          ></iframe>
          <div className="text">
            <h2>
              Presenting Julie Kabat, Composer/Performer: Excerpts from three
              works
            </h2>
            <p>
              A brief introduction to Julie’s earlier career as a concert
              artist.
              <br />
              <br />
              Scroll in 10 seconds to begin viewing this video. Segments 1 & 3
              are my favorites.
              <br />
              <br />
              1) Voice with glass harmonica, based on a poem by Wallace Stevens
              <br />
              <br />
              2) Voice with musical saw, & water jar, exploring the music of
              emotion that underlies speech
              <br />
              <br />
              3) Voice, computerized synthesizers, and koto, incorporating
              elements of Japanese Noh Theater and based on a dream
              <br />
              <br />
              Recorded and produced by Stan Davis.
            </p>
          </div>
        </div>
        <div className="media loading">
          <div className="text">
            <iframe
              height="315"
              src="https://www.youtube.com/embed/HqQwqicIUfs?si=4cjHPGcJuf-ye_tT&amp;start=578"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
              onLoad={(e) => {
                e.target.parentElement.classList.remove("loading");
              }}
            ></iframe>
            <h2>
              History Is Lunch: Julie Kabat, "My Brother's Story of Freedom
              Summer"
            </h2>
            <p>
              Julie Kabat and Gail Falk (Luke's fellow Freedom School teacher)
              present this lecture, followed by Q&A. Recorded March 20, 2024, at
              the Mississippi Civil Rights Museum, Jackson, MS.
            </p>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default AboutMedia;
