import React from "react";
import { Divide } from "../../Common";
import { JulieRead } from "../../../assets";
import { useLightbox } from "../../../hooks";
import "./styles.css";

const TopicsAccordian = () => {
  const lightbox = useLightbox();

  return (
    <div className="container topicsacc">
      <header>
        <div className="left">
          <h2>Topics for Presentation</h2>
          <p>
            The list below is not all-inclusive. Feel free to mix and match or
            come up with your own topics and questions. Julie will be happy to
            create a talk that meets your special needs.
          </p>
        </div>
        <img
          src={JulieRead}
          alt="Julie reading at a Library event"
          onClick={() =>
            lightbox.create(
              JulieRead,
              "Julie reading at a Library Event",
              null,
              null
            )
          }
          fetchpriority="high"
        />
      </header>
      <ol className="questions">
        <li>
          <h3>1. &nbsp; Motivation and Values</h3>
          <p>
            - Why would young adults, who were privileged, volunteer to go to
            Mississippi despite the risks?
            <br />
            <br />
            - What were the cultural and family values that led Luke to
            volunteer?
            <br />
            <br />- What did it mean to Luke, as a medical student and activist,
            to live a life of purpose?
          </p>
        </li>
        <li>
          <h3>2. &nbsp; Segregation: breaking down racial barriers</h3>
          <p>
            Luke wrote:
            <br />
            <br />
            “It is tragic that barriers so foolish as color differences prevent
            whites and Negroes from knowing and loving each other—and it is only
            when these barriers are destroyed that any of us can find freedom.”
            <br />
            <br />
            <br />
            - What was the experience of segregation, and how did Freedom Summer
            begin to break down racial barriers?
            <br />
            <br />
            - From his experience in Mississippi and his studies of psychology,
            how did Luke come to understand bigotry?
            <br />
            <br />- Nonviolent resistance: How do you learn to love in the face
            of hate?
          </p>
        </li>
        <li>
          <h3>
            3. &nbsp; The murders of three young civil rights workers––Mickey
            Schwerner, James Chaney and Andrew Goodman––that shocked the nation,
            and the march to James Chaney’s funeral
          </h3>
          <p>
            Knowledge of the Holocaust determined the course of Mickey
            Schwerner’s and Luke’s lives.
            <br />
            <br />
            - How did Luke honor the bond he felt with Mickey Schwerner?
            <br />
            <br />
            - What happened during Freedom Summer to open the eyes of the nation
            to racial injustice?
            <br />
            <br />- After the bodies were discovered by the FBI, how did Luke
            help the children and community to grieve?
          </p>
        </li>
        <li>
          <h3>4. &nbsp; Freedom School, a model for education</h3>
          <p>
            - What was the purpose of the Freedom Schools and what made them
            unique?
            <br />
            <br />
            - How did the Meridian Freedom School experience impact the
            students?
            <br />
            <br />- What made Luke such a beloved and effective teacher?
          </p>
        </li>
        <li>
          <h3>5. &nbsp; Freedom Songs</h3>
          <p>
            - Why was music so important for Freedom Summer and the entire Civil
            Rights Movement?
          </p>
        </li>
      </ol>
      <Divide spacing />
      <div className="special">
        <h2>Topics for Specialized Groups</h2>
        <ol>
          <li>
            <header>For Activists</header>
            <h3>
              1. &nbsp; Freedom Summer, a bold and complex experiment in
              creating social change from the ground up
            </h3>
            <p>
              - What can Freedom Summer teach us about the struggle for social
              justice, equality, and dignity that can be useful in our world
              today?
              <br />
              <br />
              - Which project elements led to its success?
              <br />
              <br />- And what happened during Freedom Summer to open the eyes
              of the nation to racial injustice?
            </p>
          </li>
          <li>
            <header>For Pre-med and Medical Students</header>
            <h3>
              2. &nbsp; Lessons in Social Justice and Medicine from Freedom
              Summer 1964
            </h3>
            <p>
              When he volunteered, Luke was a medical student at Stanford. This
              background gave him insight into the social determinants of health
              and the inequities he witnessed.
              <br />
              <br />
              - What can we learn about social justice, and the racial divide in
              American medicine by looking back at the Civil Rights Movement,
              specifically at Mississippi Freedom Summer 1964?
              <br />
              <br />
              - What was the role of the newly formed Medical Committee for
              Human Rights?
              <br />
              <br />- And what were some major changes in healthcare that occurred as a result?
            </p>
          </li>
          <li>
            <header>For Jewish Groups: "Food for Thought"</header>
            <h3>
              3. &nbsp; Shira Muroff develops programs for Southern Jewish
              communities and Mississippi history students.
            </h3>
            <p>
              She wrote:
              <br />
              <br />
              “Though secular, Jewish history and tradition had clearly made its
              impact on Luke. His volunteering was influenced by the Holocaust’s
              impact on his family only 20 years before. In a touching scene,
              Luke’s Jewish teachings and traditions come out almost by habit as
              he counsels and consoles his young students in Meridian as they
              navigate the deaths of Chaney (a student’s brother) and Schwerner
              (a favorite teacher) … Luke's detailed notes are often scanned
              into the book itself, giving readers a sense of intimacy.”
              <br />
              <span>
                -- Excerpt of a Review in the <i>Mississippi Clarion Ledger</i>
              </span>
              <br />
              <br />
              <br />
              “We included a reading from your book in our Haggadah this year at
              the Mississippi Freedom Seder (a program run by the Two
              Mississippi Museums and the Woldenberg Institute of Southern
              Jewish Life). Thank you for your (and your brother's) words, they
              definitely added meaning to our community seder.”
              <br />
              <span className="review">
                <b>Q:</b> What would be the value to your community of creating
                a Freedom Haggadah? In what ways would Luke’s story and his
                writings help you to do so?{" "}
              </span>
            </p>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default TopicsAccordian;
