import React from "react";
import { Helmet } from "react-helmet";
import {
  Navbar,
  AbtHero,
  Footer,
  Credit,
  InfoBar,
  Divide,
  AboutMedia,
  AboutQuote,
  AboutRec,
} from "../../../containers";
import "./view.css";

const About = () => {
  React.useEffect(() => {
    const url = new URL(window.location.href);
    const nav = url.searchParams.get("nav");

    if (nav && nav === "recommendations") {
      document
        .querySelector(".container.abtrec")
        .scrollIntoView({ behavior: "smooth" });
    }

    // remove the query param from the url
    window.history.replaceState({}, document.title, window.location.pathname);
  }, []);

  return (
    <div className="view about">
      <Helmet>
        <title>About</title>
      </Helmet>
      <div className="container">
        <Navbar />
        <div className="content">
          <AbtHero />
          <AboutQuote
          className="skinny"
            quote='<span class="top"> “Although Luke was eight years older, he treated me like an equal, <br/> and I never questioned the bond we had. 
                  <br />
                  <br />
                  <i>‘Hey Pig, let’s go,’</i> Luke would say. He’d turn and walk away, nonchalantly waving for me to follow him. Like an eager puppy, I always did.”'
            author="–– Julie Kabat "
          />
          <AboutMedia />
          <Divide spacing />
          <AboutRec />
          <Divide spacing />
          <InfoBar version={2} />
          <Footer />
          <Credit />
        </div>
      </div>
    </div>
  );
};

export default About;
