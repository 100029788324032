import React from "react";
import { BG1 } from "../../../assets";
import { useNotifications } from "../../../hooks";
import { Dropdown } from "../../../components";
import "./styles.css";

const EventEvents = () => {
  const events = [
    {
      id: 1,
      title: "Virtual Author Talk",
      link: "https://www.templeisraelcenter.org/",
      hasPage: false,
      priority: 1,
      public: true,
      details: {
        time: {
          noTime: false,
          date: 1736985600,
          hasDuration: false,
        },
        thumbnail: {
          url: null,
          alt: null,
        },
        location: {
          online: true,
          inPerson: false,
          name: "Temple Israel Center",
          city: "White Plains",
          state: "NY",
          link: "www.templeisraelcenter.org",
          directions: null,
        },
      },
      actions: ["bookmark"],
    },
    {
      id: 2,
      title: "Virtual Author Talk",
      link: "https://www.jccnj.org/",
      hasPage: false,
      priority: 2,
      public: true,
      details: {
        time: {
          noTime: false,
          date: 1737994500,
          hasDuration: false,
        },
        thumbnail: {
          url: null,
          alt: null,
        },
        location: {
          online: true,
          inPerson: false,
          name: "JCC of Central New Jersey",
          link: "https://www.jccnj.org/",
          directions: null,
        },
      },
      actions: ["bookmark"],
    },
    {
      id: 3,
      title: "Podcast Interview",
      link: "https://marionroach.com/",
      hasPage: false,
      priority: 1,
      redirect: {
        isEnabled: true,
        link: "https://podcasts.apple.com/us/podcast/qwerty-a-podcast-for-writers-on-how-to-live-the-writing-life/id1464072290",
      },
      public: true,
      details: {
        time: {
          noTime: true,
          date: 1731560400,
          hasDuration: false,
        },
        thumbnail: {
          url: null,
          alt: null,
        },
        location: {
          online: true,
          inPerson: false,
          name: "Marion Roach Smith",
          link: "https://marionroach.com/",
          directions: null,
        },
      },
      actions: ["bookmark", "share"],
    },
    {
      id: 4,
      title: "Virtual Author Talk",
      link: "https://www.ccjcc.org/",
      hasPage: false,
      priority: 1,
      public: true,
      details: {
        time: {
          noTime: false,
          date: 1742853600,
          hasDuration: false,
        },
        thumbnail: {
          url: null,
          alt: null,
        },
        location: {
          online: true,
          inPerson: false,
          name: "Contra Costa JCC",
          city: null,
          state: null,
          link: "https://www.ccjcc.org/",
          directions: null,
        },
      },
      actions: ["bookmark"],
    },
    {
      id: 5,
      title: "Book Group",
      link: null,
      hasPage: false,
      priority: 4,
      redirect: {
        isEnabled: false,
        link: null,
      },
      public: true,
      details: {
        time: {
          noTime: true,
          date: 1727971200,
          hasDuration: false,
        },
        thumbnail: {
          url: null,
          alt: null,
        },
        location: {
          online: false,
          inPerson: true,
          name: "Delmar, NY",
          link: null,
          directions: null,
        },
      },
    },
    {
      id: 6,
      title: "Book Group",
      link: null,
      hasPage: false,
      priority: 4,
      redirect: {
        isEnabled: false,
        link: null,
      },
      public: true,
      details: {
        time: {
          noTime: true,
          date: 1724860800,
          hasDuration: false,
        },
        thumbnail: {
          url: null,
          alt: null,
        },
        location: {
          online: false,
          inPerson: true,
          name: "Troy, NY",
          link: null,
          directions: null,
        },
      },
    },
    {
      id: 7,
      title: "Author Interview",
      link: "https://attherep.org/",
      hasPage: true,
      priority: 4,
      redirect: {
        isEnabled: true,
        // link: "/event?id=7",
      },
      public: true,
      details: {
        time: {
          noTime: true,
          date: 1715529600,
          hasDuration: false,
        },
        thumbnail: {
          url: null,
          alt: null,
        },
        location: {
          online: false,
          inPerson: true,
          name: "Capital Repertory Theatre Company",
          link: "https://maps.app.goo.gl/dyGkXGEigaQyUWVY9",
          directions: null,
        },
      },
      actions: ["directions"],
    },
    {
      id: 8,
      title: "Book Group",
      link: null,
      hasPage: false,
      priority: 4,
      redirect: {
        isEnabled: false,
        link: null,
      },
      public: true,
      details: {
        time: {
          noTime: true,
          date: 1714752000,
          hasDuration: false,
        },
        thumbnail: {
          url: null,
          alt: null,
        },
        location: {
          online: false,
          inPerson: true,
          name: "Plum Beach, RI",
          link: null,
          directions: null,
        },
      },
    },
    {
      id: 9,
      title: "Author Talk",
      link: "https://willettfree.org/",
      hasPage: false,
      priority: 4,
      redirect: {
        isEnabled: false,
        link: null,
      },
      public: true,
      details: {
        time: {
          noTime: true,
          date: 1714665600,
          hasDuration: false,
        },
        thumbnail: {
          url: null,
          alt: null,
        },
        location: {
          online: false,
          inPerson: true,
          name: "Willett Library, Saunderstown, RI",
          link: "https://maps.app.goo.gl/ajvMkENe5AYPhvo69",
          directions: null,
        },
      },
      actions: ["directions"],
    },
    {
      id: 10,
      title: "Virtual Discussion with Anti-racism Group",
      link: "https://uusouthcountyri.org/",
      hasPage: false,
      priority: 4,
      redirect: {
        isEnabled: false,
        link: null,
      },
      public: true,
      details: {
        time: {
          noTime: true,
          date: 1714406400,
          hasDuration: false,
        },
        thumbnail: {
          url: null,
          alt: null,
        },
        location: {
          online: false,
          inPerson: true,
          name: "Unitarian Church, Wakefield, RI",
          link: "https://maps.app.goo.gl/zewwunpYdndaRy7r9",
          directions: null,
        },
      },
      actions: ["directions"],
    },
    {
      id: 11,
      title: "Author Interview by Benita Zahn",
      link: "https://uusouthcountyri.org/",
      hasPage: false,
      priority: 4,
      redirect: {
        isEnabled: false,
        link: null,
      },
      public: true,
      details: {
        time: {
          noTime: true,
          date: 1714233600,
          hasDuration: false,
        },
        thumbnail: {
          url: null,
          alt: null,
        },
        location: {
          online: false,
          inPerson: true,
          name: "Capital Repertory Theater Company",
          link: "https://maps.app.goo.gl/zewwunpYdndaRy7r9",
          directions: null,
        },
      },
      actions: ["directions"],
    },
    {
      id: 12,
      title: "Author Interview by Benita Zahn",
      link: "https://uusouthcountyri.org/",
      hasPage: false,
      priority: 4,
      redirect: {
        isEnabled: false,
        link: null,
      },
      public: true,
      details: {
        time: {
          noTime: true,
          date: 1714147200,
          hasDuration: false,
        },
        thumbnail: {
          url: null,
          alt: null,
        },
        location: {
          online: false,
          inPerson: true,
          name: "Capital Repertory Theater Company",
          link: "https://maps.app.goo.gl/zewwunpYdndaRy7r9",
          directions: null,
        },
      },
      actions: ["directions"],
    },
    {
      id: 13,
      title: "Author Talk",
      link: "https://www.pegasusbookstore.com/",
      hasPage: false,
      priority: 4,
      redirect: {
        isEnabled: false,
        link: null,
      },
      public: true,
      details: {
        time: {
          noTime: true,
          date: 1713196800,
          hasDuration: false,
        },
        thumbnail: {
          url: null,
          alt: null,
        },
        location: {
          online: false,
          inPerson: true,
          name: "Pegasus Books on Solano, Berkeley, CA",
          link: "https://maps.app.goo.gl/z6ezWra7F7JzZrJK8",
          directions: null,
        },
      },
      actions: ["directions"],
    },
    {
      id: 14,
      title: "Author Talk - “Freedom Summer, Social Justice, and Medicine”",
      link: "https://med.stanford.edu/lcgm",
      hasPage: true,
      priority: 4,
      redirect: {
        isEnabled: true,
        // link: "/event?id=14",
      },
      public: true,
      details: {
        time: {
          noTime: true,
          date: 1712937600,
          hasDuration: false,
        },
        thumbnail: {
          url: null,
          alt: null,
        },
        location: {
          online: false,
          inPerson: true,
          name: "Stanford School of Medicine, Laboratory for Cell and Gene Medicine",
          link: "https://maps.app.goo.gl/sABfxNP6gbePBRoH7",
          directions: null,
        },
      },
      actions: ["directions"],
    },
    {
      id: 15,
      title: "Author Talk",
      link: "https://humanrights.stanford.edu/",
      hasPage: true,
      priority: 4,
      redirect: {
        isEnabled: true,
        // link: "/event?id=15",
      },
      public: true,
      details: {
        time: {
          noTime: true,
          date: 1712851200,
          hasDuration: false,
        },
        thumbnail: {
          url: null,
          alt: null,
        },
        location: {
          online: false,
          inPerson: true,
          name: "Center for Human Rights and International Justice, Stanford University",
          link: "https://maps.app.goo.gl/81qR69Gh73LL1TpU7",
          directions: null,
        },
      },
      actions: ["directions"],
    },
    {
      id: 16,
      title: "Author Talk",
      link: "https://northchathamlibrary.org/",
      hasPage: false,
      priority: 4,
      redirect: {
        isEnabled: false,
        link: null,
      },
      public: true,
      details: {
        time: {
          noTime: true,
          date: 1712505600,
          hasDuration: false,
        },
        thumbnail: {
          url: null,
          alt: null,
        },
        location: {
          online: false,
          inPerson: true,
          name: "North Chatham Free Library",
          link: "https://maps.app.goo.gl/GaDmGuURpitiNTYi8",
          directions: null,
        },
      },
      actions: ["directions"],
    },
    {
      id: 17,
      title: "‘History Is Lunch’ Presentation",
      link: "https://mcrm.mdah.ms.gov/",
      hasPage: true,
      priority: 4,
      redirect: {
        isEnabled: true,
        link: "https://www.youtube.com/watch?v=HqQwqicIUfs&t=578s",
      },
      public: true,
      details: {
        time: {
          noTime: true,
          date: 1710950400,
          hasDuration: false,
        },
        thumbnail: {
          url: null,
          alt: null,
        },
        location: {
          online: false,
          inPerson: true,
          name: "Mississippi Civil Rights Museum",
          link: "https://maps.app.goo.gl/miqhLooYYheR86Ea9",
          directions: null,
        },
      },
      actions: ["directions"],
    },
    {
      id: 18,
      title: "Author Interview by Kobee Vance",
      link: "https://www.mpbonline.org/",
      hasPage: false,
      priority: 4,
      redirect: {
        isEnabled: false,
        link: null,
      },
      public: true,
      details: {
        time: {
          noTime: true,
          date: 1710864000,
          hasDuration: false,
        },
        thumbnail: {
          url: null,
          alt: null,
        },
        location: {
          online: false,
          inPerson: true,
          name: "MPB News (WJSU-FM), Jackson, MS",
          link: null,
          directions: null,
        },
      },
    },
    {
      id: 19,
      title:
        "Journey to Freedom: Honoring Veterans of the Civil Rights Movement",
      link: "https://www.siena.edu/offices/academics/academic-events-and-programs/mlk-lecture-series/journey-to-freedom-2/videos/",
      hasPage: false,
      priority: 4,
      redirect: {
        isEnabled: false,
        link: null,
      },
      public: true,
      details: {
        time: {
          noTime: true,
          date: 1707238800,
          hasDuration: false,
        },
        thumbnail: {
          url: null,
          alt: null,
        },
        location: {
          online: true,
          inPerson: false,
          name: "Siena College, Loudonville, NY",
          link: null,
          directions: null,
        },
      },
    },
    {
      id: 20,
      title: "Senior Study Group Discussion",
      link: null,
      hasPage: false,
      priority: 4,
      redirect: {
        isEnabled: false,
        link: null,
      },
      public: true,
      details: {
        time: {
          noTime: true,
          date: 1707152400,
          hasDuration: false,
        },
        thumbnail: {
          url: null,
          alt: null,
        },
        location: {
          online: false,
          inPerson: true,
          name: "Lees Summit, MO",
          link: null,
          directions: null,
        },
      },
    },
    {
      id: 21,
      title: "Classroom Presentations",
      link: "https://www.iolani.org/",
      hasPage: false,
      priority: 4,
      redirect: {
        isEnabled: false,
        link: null,
      },
      public: true,
      details: {
        time: {
          noTime: true,
          date: 1706893200,
          hasDuration: false,
        },
        thumbnail: {
          url: null,
          alt: null,
        },
        location: {
          online: false,
          inPerson: true,
          name: "Iolani School, Honolulu, HI",
          link: null,
          directions: null,
        },
      },
    },
    {
      id: 22,
      title: "Classroom Presentations",
      link: "https://www.iolani.org/",
      hasPage: false,
      priority: 4,
      redirect: {
        isEnabled: false,
        link: null,
      },
      public: true,
      details: {
        time: {
          noTime: true,
          date: 1706806800,
          hasDuration: false,
        },
        thumbnail: {
          url: null,
          alt: null,
        },
        location: {
          online: false,
          inPerson: true,
          name: "Iolani School, Honolulu, HI",
          link: null,
          directions: null,
        },
      },
    },
    {
      id: 23,
      title: "Book Group",
      link: null,
      hasPage: false,
      priority: 4,
      redirect: {
        isEnabled: false,
        link: null,
      },
      public: true,
      details: {
        time: {
          noTime: true,
          date: 1706374800,
          hasDuration: false,
        },
        thumbnail: {
          url: null,
          alt: null,
        },
        location: {
          online: false,
          inPerson: true,
          name: "Honolulu, HI",
          link: null,
          directions: null,
        },
      },
    },
    {
      id: 24,
      title: 'Virtual Discussion, "Breaking Down Racial Barriers"',
      link: "https://www.upress.state.ms.us/",
      hasPage: true,
      priority: 4,
      redirect: {
        isEnabled: true,
        link: "https://www.youtube.com/watch?v=_S1FDU5_sBI",
      },
      public: true,
      details: {
        time: {
          noTime: true,
          date: 1700067600,
          hasDuration: false,
        },
        thumbnail: {
          url: null,
          alt: null,
        },
        location: {
          online: false,
          inPerson: true,
          name: "University Press of Mississippi, Jackson, MS",
          link: null,
          directions: null,
        },
      },
    },
    {
      id: 25,
      title: 'Virtual Discussion, "Making History"',
      link: "https://www.upress.state.ms.us/",
      hasPage: true,
      priority: 4,
      redirect: {
        isEnabled: true,
        link: "https://www.youtube.com/watch?v=ULiktVYlBBk",
      },
      public: true,
      details: {
        time: {
          noTime: true,
          date: 1700067600,
          hasDuration: false,
        },
        thumbnail: {
          url: null,
          alt: null,
        },
        location: {
          online: false,
          inPerson: true,
          name: "University Press of Mississippi, Jackson, MS",
          link: null,
          directions: null,
        },
      },
    },
    {
      id: 26,
      title:
        "Author Interview by Ann Parillo with sisters Andreesa Coleman and Dorothy Singletary",
      link: "https://capitalregiontoday.org/",
      hasPage: true,
      priority: 4,
      redirect: {
        isEnabled: true,
        link: "https://capitalregiontoday.org/interview-with-julie-kabat-author-of-love-letter-from-pig-my-brothers-story-of-freedom-summer-1964/",
      },
      public: true,
      details: {
        time: {
          noTime: true,
          date: 1699462800,
          hasDuration: false,
        },
        thumbnail: {
          url: null,
          alt: null,
        },
        location: {
          online: false,
          inPerson: true,
          name: "Capital Region TV Today",
          link: null,
          directions: null,
        },
      },
    },
    {
      id: 27,
      title: "Ethics Grand Rounds",
      link: "https://www.amc.edu/education/alden-march-bioethics-institute/",
      hasPage: true,
      priority: 4,
      redirect: {
        isEnabled: true,
        link: "https://www.youtube.com/watch?v=C4qC0OxMzfo",
      },
      public: true,
      details: {
        time: {
          noTime: true,
          date: 1698940800,
          hasDuration: false,
        },
        thumbnail: {
          url: null,
          alt: null,
        },
        location: {
          online: true,
          inPerson: false,
          name: "Alden March Bioethics Center, Albany Medical College",
          link: null,
          directions: null,
        },
      },
    },
    {
      id: 28,
      title: "Author Interview by Paul Murray",
      link: "https://www.bhny.com/",
      hasPage: false,
      priority: 4,
      redirect: {
        isEnabled: false,
        link: null,
      },
      public: true,
      details: {
        time: {
          noTime: true,
          date: 1695916800,
          hasDuration: false,
        },
        thumbnail: {
          url: null,
          alt: null,
        },
        location: {
          online: false,
          inPerson: true,
          name: "The Book House at Stuyvesant Plaza",
          link: "https://maps.app.goo.gl/MtgYe7XaeJ5ehgVx8",
          directions: null,
        },
      },
      actions: ["directions"],
    },
    {
      id: 29,
      title: "Author Interview by Paul Murray",
      link: "https://spencertownacademy.org/festival-of-books/",
      hasPage: false,
      priority: 4,
      redirect: {
        isEnabled: false,
        link: null,
      },
      public: true,
      details: {
        time: {
          noTime: true,
          date: 1693756800,
          hasDuration: false,
        },
        thumbnail: {
          url: null,
          alt: null,
        },
        location: {
          online: false,
          inPerson: true,
          name: "Spencertown Academy Festival of Books",
          link: "https://maps.app.goo.gl/92zfpAiCmVpbj8ch9",
          directions: null,
        },
      },
      actions: ["directions"],
    },
    {
      id: 30,
      title: "Virtual Author Talk",
      link: "https://vassartemple.org/",
      hasPage: false,
      priority: 2,
      redirect: {
        isEnabled: false,
        link: null,
      },
      public: true,
      details: {
        time: {
          noTime: false,
          date: 1740596400,
          hasDuration: false,
        },
        thumbnail: {
          url: null,
          alt: null,
        },
        location: {
          online: false,
          inPerson: true,
          name: "Vassar Temple",
          link: "https://maps.app.goo.gl/92zfpAiCmVpbj8ch9",
          directions: null,
        },
      },
      actions: ["bookmark"],
    },
    {
      id: 31,
      title: "Author Talk",
      link: "https://vassartemple.org/",
      hasPage: false,
      priority: 2,
      redirect: {
        isEnabled: false,
        link: null,
      },
      public: true,
      details: {
        time: {
          noTime: false,
          date: 1743361200,
          hasDuration: false,
        },
        thumbnail: {
          url: null,
          alt: null,
        },
        location: {
          online: false,
          inPerson: true,
          name: "Vassar Temple",
          link: "https://maps.app.goo.gl/kztoe4EbVnid1BDL6",
          directions: null,
        },
      },
      actions: ["bookmark", "directions"],
    },
  ];
  const [isMobile, setIsMobile] = React.useState(false);
  const [filter, setFilter] = React.useState("Upcoming");

  const getMonthYear = (date) => {
    return date.toLocaleString("default", { month: "long", year: "numeric" });
  };

  const renderEvents = () => {
    const now = Date.now() / 1000; // Current timestamp in seconds

    // Filter events based on 'filter' state
    const filteredEvents = events.filter((event) =>
      filter === "Upcoming"
        ? event.details.time.date > now
        : event.details.time.date <= now
    );

    // Sort events by date
    const sortedEvents = filteredEvents.sort((a, b) =>
      filter === "Upcoming"
        ? a.details.time.date - b.details.time.date
        : b.details.time.date - a.details.time.date
    );

    // Group events by month-year
    const groupedEvents = sortedEvents.reduce((acc, event) => {
      const eventDate = new Date(event.details.time.date * 1000);
      const monthYear = eventDate.toLocaleString("default", {
        month: "long",
        year: "numeric",
      });
      if (!acc[monthYear]) {
        acc[monthYear] = [];
      }
      acc[monthYear].push(event);
      return acc;
    }, {});

    return Object.entries(groupedEvents).map(([monthYear, events], index) => (
      <div className="month-wrapper" key={index}>
        <div className="month">
          <span className="name">{monthYear}</span>
          <span className="line"></span>
        </div>
        {events.map((event, idx) =>
          isMobile ? (
            <MobileEvent key={event.id} event={event} />
          ) : (
            <Event key={event.id} event={event} />
          )
        )}
      </div>
    ));
  };
  function handleFilterChange(value) {
    setFilter(value);
  }

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 650);
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="container eventevents">
      <div className="wrapper">
        <header>
          <h2>Calendar</h2>
        </header>
        <div className="events">
          <div className="top">
            <div className="right">
              <Dropdown
                options={["Upcoming", "Previous"]}
                selected={filter}
                onChange={handleFilterChange}
              />
            </div>
          </div>
          <div className="event_list">{renderEvents()}</div>
        </div>
      </div>
    </div>
  );
};

const Event = ({ event }) => {
  const time = new Date(event?.details?.time?.date * 1000);
  const timeString = time.toLocaleString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  const date = new Date();
  const dateString = date.toLocaleTimeString("en-us", {
    timeZoneName: "short",
  });
  const timeZone = dateString.split(" ").pop();

  const threeLetterDay = time.toLocaleString("en-US", { weekday: "short" });
  const dayNumber = time.toLocaleString("en-US", { day: "numeric" });

  return (
    <div className="event">
      <div className="left">
        <span className="day">{threeLetterDay}</span>
        <span className="date">{dayNumber}</span>
      </div>
      <div className="right">
        <div className="time">
          {event?.details?.time?.date &&
          !event?.details?.time?.hasDuration &&
          !event?.details?.time?.noTime ? (
            <span className="time">
              {timeString} <i className="tz">{timeZone}</i>
            </span>
          ) : event?.details?.time?.date &&
            event?.details?.time?.hasDuration ? (
            <span className="time">{}</span>
          ) : (
            <span className="time"></span>
          )}
        </div>
        <div className="title">
          <h3>{event?.title}</h3>
          <a
            target="_blank"
            className={event?.link ? "link" : ""}
            href={event?.link}
          >
            {event?.details?.location?.name}
          </a>
        </div>
        <div className="actions">
          {event?.actions?.map((action, i) => {
            if (action === "bookmark") {
              return (
                <a key={i} className="action">
                  <span className="material-symbols-outlined icon">
                    bookmark
                  </span>
                  <span className="tooltip">Bookmark</span>
                </a>
              );
            }

            if (action === "directions") {
              return (
                <a
                  target="_blank"
                  href={event?.details?.location?.link}
                  key={i}
                  className="action"
                >
                  <span className="material-symbols-outlined icon">
                    location_on
                  </span>
                  <span className="tooltip">Get Directions</span>
                </a>
              );
            }

            if (action === "share") {
              return (
                <a key={i} className="action">
                  <span className="material-symbols-outlined icon">share</span>
                  <span className="tooltip">Share</span>
                </a>
              );
            }
          })}
        </div>
        {event?.redirect?.isEnabled ? (
          <div className="button">
            <a target="_blank" href={event?.redirect?.link}>
              View Event
              <span className="material-symbols-outlined icon">
                arrow_forward
              </span>
            </a>
          </div>
        ) : event?.hasPage ? (
          <div className="button">
            <a target="_blank" href="">
              View Event
              <span className="material-symbols-outlined icon">
                arrow_forward
              </span>
            </a>
          </div>
        ) : (
          <div className="button"></div>
        )}
      </div>
    </div>
  );
};

const MobileEvent = ({ event }) => {
  const time = new Date(event?.details?.time?.date * 1000);
  const timeString = time.toLocaleString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  const date = new Date();
  const dateString = date.toLocaleTimeString("en-us", {
    timeZoneName: "short",
  });
  const timeZone = dateString.split(" ").pop();

  const threeLetterDay = time.toLocaleString("en-US", { weekday: "short" });
  const dayNumber = time.toLocaleString("en-US", { day: "numeric" });

  return (
    <div className={`event mobile`}>
      <div className="header">
        <div className="title">
          <h3>{event?.title}</h3>
          <a
            className={event?.link ? "link" : ""}
            target="_blank"
            href={event?.link}
          >
            {event?.details?.location?.name}
          </a>
        </div>
        <div className="when">
          <span className="day">{threeLetterDay}</span>
          <span className="date">{dayNumber}</span>
        </div>
      </div>
      <div className="middle">
        <div className="actions">
          {event?.actions?.map((action, i) => {
            if (action === "bookmark") {
              return (
                <div key={i} className="action">
                  <span className="material-symbols-outlined icon">
                    bookmark
                  </span>
                  <span className="tooltip">Bookmark</span>
                </div>
              );
            }

            if (action === "directions") {
              return (
                <div key={i} className="action">
                  <span className="material-symbols-outlined icon">
                    location_on
                  </span>
                  <span className="tooltip">Get Directions</span>
                </div>
              );
            }

            if (action === "share") {
              return (
                <div key={i} className="action">
                  <span className="material-symbols-outlined icon">share</span>
                  <span className="tooltip">Share</span>
                </div>
              );
            }
          })}
        </div>
        <div className="time">
          {event?.details?.time?.date &&
          !event?.details?.time?.hasDuration &&
          !event?.details?.time?.noTime ? (
            <span className="time">
              {timeString} <i className="tz">{timeZone}</i>
            </span>
          ) : event?.details?.time?.date &&
            event?.details?.time?.hasDuration ? (
            <span className="time">{}</span>
          ) : (
            <span className="time"></span>
          )}
        </div>
      </div>
      <div className="bottom">
        {event?.redirect?.isEnabled ? (
          <div className="button">
            <a href={event?.redirect?.link}>
              View Event
              <span className="material-symbols-outlined icon">
                arrow_forward
              </span>
            </a>
          </div>
        ) : event?.hasPage ? (
          <div className="button">
            <a href="">
              View Event
              <span className="material-symbols-outlined icon">
                arrow_forward
              </span>
            </a>
          </div>
        ) : (
          <div className="button"></div>
        )}
      </div>
    </div>
  );
};

export default EventEvents;
