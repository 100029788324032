import React from "react";
import { useNotifications } from "../../../hooks";
import { axios } from "../../../utils";
import { BG2 } from "../../../assets";
import "./styles.css";

const ContactForm = () => {
  const [c, setC] = React.useState({ n: "", s: "", e: "", m: "", fake: null });
  const [e, setE] = React.useState({
    n: false,
    s: false,
    e: false,
    m: false,
    fake: false,
  });
  const [isInputLoading, setInputLoading] = React.useState(false);
  const [isSubmitLoading, setSubmitLoading] = React.useState(false);
  const inputRefs = React.useRef([]);
  const noti = useNotifications();

  function handleFocus(e) {
    e.target.parentNode.classList?.add("focus");
    e.target.parentNode.classList?.add("not-empty");
  }

  function handleBlur(e) {
    if (e.target.value === "") {
      e.target.parentNode.classList?.remove("not-empty");
    }

    e.target.parentNode.classList?.remove("focus");
  }

  function addNotEmpty(e) {
    e.target.parentNode.classList?.add("not-empty");
  }

  async function handleSubmit(e) {
    e.preventDefault();

    await document.activeElement.blur();

    if (isSubmitLoading) return;
    if (isInputLoading) return;

    setSubmitLoading(true);

    if (!c.n || !c.s || !c.e || !c.m) {
      setE({ n: !c.n, s: !c.s, e: !c.e, m: !c.m });
      setSubmitLoading(false);
      noti.create(
        {
          name: "error",
          background: "red",
          color: "#fff",
        },
        {
          title: "Unable To Submit",
          message: "Missing Fields",
          color: "#000",
        },
        "red",
        5000
      );
      return;
    }

    setE({ n: false, s: false, e: false, m: false });

    try {
      // Get the users current ip and location data
      const ip = await axios.get("https://api.ipify.org?format=json");

      const response = await axios.post(
        "/contact",
        {
          name: c.n,
          subject: c.s,
          email: c.e,
          message: c.m,
          captcha: c.fake,
          ip: ip.data.ip,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        noti.create(
          {
            name: "check",
            background: "green",
            color: "#fff",
          },
          {
            title: "Submitted Successfully",
            message: "We will get back to you soon",
            color: "#000",
          },
          "green",
          5000
        );
        setSubmitLoading(false);
        setC({ n: "", s: "", e: "", m: "" });
        inputRefs.current.forEach((input) =>
          input.parentNode.classList.remove("not-empty")
        );
      }
    } catch (error) {
      console.error("[LOG] Error submitting form", error);

      if (!error.response || !error.response.data) {
        setSubmitLoading(false);
        return noti.create(
          {
            name: "error",
            background: "red",
            color: "#fff",
          },
          {
            title: "Unable To Submit",
            message: "An error occured",
            color: "#000",
          },
          "red",
          5000
        );
      }

      if ((error.response.data.message = "Invalid email address.")) {
        setE({ ...e, e: true });
        setC({ ...c, e: "" });
        setSubmitLoading(false);
        return noti.create(
          {
            name: "error",
            background: "red",
            color: "#fff",
          },
          {
            title: "Unable To Submit",
            message: "Invalid email address",
            color: "#000",
          },
          "red",
          5000
        );
      }
    }
  }

  React.useEffect(() => {
    if (c.f) setE({ ...e, n: false });
    if (c.l) setE({ ...e, s: false });
    if (c.e) setE({ ...e, e: false });
    if (c.m) setE({ ...e, m: false });
    if (c.fake) setE({ ...e, fake: false });
  }, [c]);

  return (
    <div className="container contactform">
      <div className="contact">
        <div className="container">
          <div className="left">
            <div className="form-wrapper">
              <div className="contact-heading">
                <h1>
                  Get in Touch<span>.</span>
                </h1>
                <p className="text">
                  Or reach us via{" "}
                  <a href="mailto:juliekabat1964@gmail.com">
                    juliekabat1964@gmail.com
                  </a>
                </p>
              </div>

              <form>
                <div className={`input-wrapper ${e.n === true ? "err" : ""}`}>
                  <input
                    autoComplete="off"
                    name="Name"
                    required
                    type="text"
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setC({ ...c, n: e.target.value });
                      addNotEmpty(e);
                    }}
                    value={c.n}
                    disabled={isSubmitLoading}
                    ref={(el) => (inputRefs.current[0] = el)}
                  />
                  <label>Name</label>
                  <span className="material-symbols-outlined icon">
                    contact_mail
                  </span>
                </div>
                <div className={`input-wrapper ${e.s === true ? "err" : ""}`}>
                  <input
                    autoComplete="off"
                    name="Subject"
                    required
                    type="text"
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setC({ ...c, s: e.target.value });
                      addNotEmpty(e);
                    }}
                    value={c.s}
                    disabled={isSubmitLoading}
                    ref={(el) => (inputRefs.current[1] = el)}
                  />
                  <label>Subject</label>
                  <span className="material-symbols-outlined icon">
                    contact_mail
                  </span>
                </div>
                <div
                  className={`input-wrapper w-100 ${e.e === true ? "err" : ""}`}
                >
                  <input
                    autoComplete="off"
                    name="Email"
                    required
                    type="email"
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setC({ ...c, e: e.target.value });
                      addNotEmpty(e);
                    }}
                    value={c.e}
                    disabled={isSubmitLoading}
                    ref={(el) => (inputRefs.current[2] = el)}
                  />
                  <label>Email</label>
                  <span className="material-symbols-outlined icon">send</span>
                </div>
                <div
                  className={`input-wrapper hidden ${
                    e.fake === true ? "err" : ""
                  }`}
                >
                  <input
                    autoComplete="off"
                    name="First Name"
                    required
                    type="text"
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setC({ ...c, fake: e.target.value });
                      addNotEmpty(e);
                    }}
                    value={c.f}
                    disabled={isSubmitLoading}
                    ref={(el) => (inputRefs.current[3] = el)}
                  />
                  <label>Cpatcha Test</label>
                  <span className="material-symbols-outlined icon">
                    contact_mail
                  </span>
                </div>
                <div
                  className={`input-wrapper w-100 ${e.m === true ? "err" : ""}`}
                >
                  <textarea
                    name="Message"
                    autoComplete="off"
                    required
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setC({ ...c, m: e.target.value });
                      addNotEmpty(e);
                    }}
                    value={c.m}
                    disabled={isSubmitLoading}
                    ref={(el) => (inputRefs.current[4] = el)}
                  ></textarea>
                  <label>Message</label>
                  <span className="material-symbols-outlined icon">
                    comment
                  </span>
                </div>
                <div className="contact-buttons w-100">
                  {/* <button disabled={isSubmitLoading} onClick={handleAddAttachment} className={`btn upload ${isInputLoading ? "loading" : ""}`}>
                    <span>
                      <span className="material-symbols-outlined icon">
                        attach_file
                      </span>
                      Add attachment
                    </span>
                  </button> */}
                  <button
                    disabled={isSubmitLoading}
                    onClick={handleSubmit}
                    className={`${isSubmitLoading ? "loading" : ""}`}
                  >
                    <span>Submit</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="right">
            <div className="image-wrapper">
              <img
                src={BG2}
                alt="Photo of Julie Kabat with Luke's Widow at a book group outside of Kansas City, MO"
              />
              <div className="wave-wrapper">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 783 1536"
                  fill="none"
                >
                  <path
                    id="wave"
                    d="M236.705 1356.18C200.542 1483.72 64.5004 1528.54 1 1535V1H770.538C793.858 63.1213 797.23 196.197 624.165 231.531C407.833 275.698 274.374 331.715 450.884 568.709C627.393 805.704 510.079 815.399 347.561 939.282C185.043 1063.17 281.908 1196.74 236.705 1356.18Z"
                  />
                </svg>
              </div>
              <svg
                viewBox="0 0 345 877"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="dashed-svg"
              >
                <path
                  id="dashed"
                  d="M0.5 876C25.6667 836.167 73.2 739.8 62 673C48 589.5 35.5 499.5 125.5 462C215.5 424.5 150 365 87 333.5C24 302 44 237.5 125.5 213.5C207 189.5 307 138.5 246 87C185 35.5 297 1 344.5 1"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
