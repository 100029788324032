import React from "react";
import { Lenray, Luke } from "../../../assets";
import { useLightbox } from "../../../hooks";
import "./styles.css";

const Reviews = () => {
  const lightbox = useLightbox();

  return (
    <div className="container reviewscont">
      <div className="wrapper">
        <header>
          <h2>What People are Saying</h2>
        </header>
        <div className="reviews">
          <div className="row">
            <div className="box">
              <img
                src={Lenray}
                onClick={() =>
                  lightbox.create(
                    Lenray,
                    "Lenray Gandy, age 9",
                    "Lenray Gandy (9)",
                    "Photo by Bill Rodd"
                  )
                }
                alt="Photo of Lenray Gandy, age 9"
              />
              <div className="text">
                <span className="title">Lenray Gandy, age 9</span>
                <span className="attrib">Photo by Bill Rodd</span>
              </div>
            </div>
            <div className="review">
              <p>
                "This story is an in-depth look at the life of a history maker,
                a change agent, a blazing star called Lucien Kabat. He was our
                teacher, and he was our friend. He fought bigotry with kindness,
                violence with humility, injustice through peace, and hate with
                love. It makes me believe he was an angel on loan to us from the
                Heavens. Rest well dear friend. Some day we will overcome!"
              </p>
              <span>
                — Lenray Gandy, NextGeneration NMCI IT Specialist Lead at Naval
                Air Station, Meridian, Mississippi
              </span>
            </div>
          </div>
          <div className="row">
            <div className="review">
              <p>
                "Luke’s kid sister, Julie Kabat, has written a stirring and
                evocative memoir of her late brother, skillfully woven together
                with a biography of their family… There are places in this book
                that are suspenseful, others that are philosophical. But
                throughout the 28 chapters, plus a curiosity-quenching
                afterword, author’s note and coda-like acknowledgments, Julie
                succeeds in her goals: to praise Luke’s work, as well as that of
                his fellow volunteers; to take another look at her relationship
                with Luke and their deep affection for one another; to make him
                live again through his letters so that readers will really know
                him. And as personal and heartfelt as her intentions were, Julie
                has also put forth an important reminder of a very divisive time
                in the US, with its tough lessons for the present day."
              </p>
              <span>
                —Johnnie Rodriguez, The Rhode Island Independent and The
                Standard Times, April 25, 2024
              </span>
            </div>
            <div className="box">
              <img
                src={Luke}
                onClick={() =>
                  lightbox.create(
                    Luke,
                    "COFO Office in Meridian. Luke with Lance Williams, sisters Andreesa and Dorothy Thompson, and Linda Martin.",
                    "Luke with children and Freedom School students",
                    "Photo by Bill Rodd"
                  )
                }
                alt="Image of COFO Office in Meridian. Luke with Lance Williams, sisters Andreesa and Dorothy Thompson, and Linda Martin."
              />
              <div className="text">
                <span className="title">
                  COFO Office in Meridian. Luke with Lance Williams, sisters
                  Andreesa and Dorothy Thompson, and Linda Martin.{" "}
                </span>
                <span className="attrib">Photo by Bill Rodd</span>
              </div>
            </div>
          </div>
        </div>
        <div className="reviews-fade">
          <a href="/reviews" className="link-to-more">
            Read More Reviews
            <span className="material-symbols-outlined icon">
              arrow_forward
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
