import React from "react";
import { useAuth, useNotifications } from "../../../hooks";
import { useNavigate } from "react-router-dom";
import "./styles.css";

const LoginForm = ({}) => {
  const [c, setC] = React.useState({ u: "", p: "", c: "" });
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isRemembered, setIsRemembered] = React.useState(
    localStorage.getItem("remember") ? true : false
  );
  const [isPasswordShown, setIsPasswordShown] = React.useState(false);
  const [result, setResult] = React.useState(null);
  const auth = useAuth();
  const noti = useNotifications();
  const navigate = useNavigate();

  function handleRememberChange() {
    if (isLoading) return;
    if (isRemembered) {
      localStorage.removeItem("remember");
      setIsRemembered(false);
    } else {
      localStorage.setItem("remember", true);
      setIsRemembered(true);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setResult(null);
    if (isLoading) return;
    setIsLoading(true);

    const result = await auth.login(c.u, c.p, c.c);


    if (result.success) {
      noti.create(
        {
          name: "check",
          background: "green",
          color: "#fff",
        },
        {
          title: "Login Successful",
          message: `Welcome back ${result.u}`,
          color: "#000",
        },
        "green",
        5000
      );
      return navigate("/dashboard");
    } else if (result.errCode === 1) {
      setResult({ message: "Unable to retrieve location", color: "#c72131", code: 1 });
    } else if (result.errCode === 2) {
      setResult({ message: "Unable to reach the server", color: "#c72131", code: 2 });
    } else if (result.errCode === 3) {
      setResult({ message: "Invalid Credentials", color: "#c72131", code: 3 });
    }

    return setIsLoading(false);
  }

  React.useEffect(() => {
    if(result && result.code !== 2) {
      setResult(null);
    }

    if (c.u && c.p) {
      setIsDisabled(false);
    }
  }, [c]);

  return (
    <div className="container loginform">
      <div className="wrapper">
        <header>
          <h1>Welcome</h1>
          <p>Login to the Panel</p>
        </header>
        {result && (
          <div style={{ background: `${result.color}` }} className="result">
            <p>{result.message}</p>
          </div>
        )}
        <form className={`${result ? "less" : ""}`}>
          <div className="inputs">
            <div className="input-container">
              <label htmlFor="username">Username</label>
              <input
                value={c.u}
                onChange={(e) => setC({ ...c, u: e.target.value })}
                autoComplete="true"
                id="username"
                type="text"
                disabled={isLoading}
              />
            </div>
            <div className="input-container">
              <label htmlFor="password">Password</label>
              <div className="container">
                <input
                  id="password"
                  type={isPasswordShown ? "text" : "password"}
                  value={c.p}
                  onChange={(e) => setC({ ...c, p: e.target.value })}
                  disabled={isLoading}
                />
                <i
                  onClick={() => setIsPasswordShown(!isPasswordShown)}
                  className="bx bx-hide icon"
                />
              </div>
            </div>
            <div className="input-container hide">
              <label htmlFor="confirm">Password</label>
              <input
                id="confirm"
                type="password"
                value={c.c}
                onChange={(e) => setC({ ...c, c: e.target.value })}
              />
            </div>
          </div>
          <div className="actions">
            <div onClick={() => handleRememberChange()} className="left">
              <div className={`toggle ${isRemembered ? "on" : ""}`}>
                <div className="button"></div>
              </div>
              <div className="text">Remember Me</div>
            </div>
            <div className="right">
              <a disabled={isLoading} href="">
                Forgot Password
              </a>
            </div>
          </div>
          <button
            onClick={handleSubmit}
            disabled={isDisabled}
            className={isLoading ? "loading" : ""}
          >
            <p>Login</p>
          </button>
          <a target="_blank" href="https://rgmproductions.com" className="cred">
            Powered by <span>RGM Productions Inc.</span>
          </a>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
